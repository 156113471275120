import React from "react";
import under from "../../assets/image/underconst.jpg";

const ContactPage = () => {
  return (
    <div className="flex flex-col items-center justify-center h-screen bg-gray-100">
      <h1 className="text-3xl font-bold text-gray-800 mb-4">
        This page is under construction
      </h1>
      <img
        src={under}
        alt="Under construction"
        className="max-w-max h-64 mb-8"
      />
      <p className="text-base text-gray-600 text-center">
        We're sorry, this page is currently being worked on and will be
        available soon. Thank you for your patience.
      </p>
    </div>
  );
};

export default ContactPage;
