import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

// import component
import CLP from "../../components/content1/Content1";
import CLP2 from "../../components/content2/Content2";
import CLP3 from "../../components/content3/Content3";
import CLPCC from "../../components/contentcolor/Contentcolor";
import FOOT1 from "../../components/footer1/Footer1";
import FOOTCLR from "../../components/footercolor/Footercolor";

const DashboardAdmin = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    const role = user?.role;
    if (!role || !role.includes("admin")) {
      navigate("/");
    }
  }, [navigate]);

  return (
    <>
    <div className="flex lg:flex-row md:flex-row xs:flex-col w-[100vw] lg:py-[10rem] md:py-20 min-h-[100vh] lg:justify-around px-5">
      <CLP />
    </div>
     <div className="flex lg:flex-row md:flex-row xs:flex-col w-[100vw] lg:py-[10rem] md:py-20 min-h-[100vh] lg:justify-around px-5">
     <CLP2 />
   </div>
   <div className="flex lg:flex-row md:flex-row xs:flex-col w-[100vw] lg:py-[10rem] md:py-20 min-h-[100vh] lg:justify-around px-5">
     <CLP3 />
   </div>
   <div className="flex lg:flex-row md:flex-row xs:flex-col w-[100vw] lg:py-[10rem] md:py-20 min-h-[100vh] lg:justify-around px-5">
     <CLPCC />
   </div>
   <div className="flex lg:flex-row md:flex-row xs:flex-col w-[100vw] lg:py-[10rem] md:py-20 min-h-[100vh] lg:justify-around px-5">
     <FOOT1 />
   </div>
   <div className="flex lg:flex-row md:flex-row xs:flex-col w-[100vw] lg:py-[10rem] md:py-20 min-h-[100vh] lg:justify-around px-5">
     <FOOTCLR />
   </div>
   </>
  );
};

export default DashboardAdmin;
