import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

// component
import LandingPage from "./pages/landingpage/LandingPage";
import DashboardAdmin from "./pages/dashboardadmin/DashboardAdmin";
import Contact from "./pages/contactpage/ContactPage";
import Product from "./pages/productpage/ProductPage";
import About from "./pages/aboutpage/AboutPage";
import Footer from "./components/footer/Footer";
import Header from "./components/header/Header";
import Login from "./components/login/Login";
import Register from "./components/register/Register";

function App() {
  return (
    <BrowserRouter>
      <div className="min-w-[100vw] min-h-full">
        <Header />
        <Routes>
          <Route>
            <Route path="/" element={<LandingPage />} />
            <Route path="/about" element={<About />} />
            <Route path="/product" element={<Product />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/dashboardadmin" element={<DashboardAdmin />} />
          </Route>
        </Routes>

        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;
