import React, { useState, useEffect } from "react";
import axios from "../../api/axios";

// assets
// import logoMayapada from "../../assets/image/MayapadaLogoWhite.png";

const Footer = () => {
  const [name, setName] = useState([]);
  const [fff, setFff] = useState([]);
  const [copyright, setCopy] = useState([]);
  const [color, setColor] = useState([]);
  const fetchFooter = async () => {
    const color = await axios.get('/footercolor');
    setColor(color.data);
    const footer1 = await axios.get('/footer1');
    setName(footer1.data);
    const copyright = await axios.get('/footer2');
    setCopy(copyright.data);
    const footer = await axios.get('/footer');
    setFff(footer.data);
    };

  useEffect(() => {
    fetchFooter();
  }, []);
  return (
    <>
    {color.map((color) => (
      <div className="flex flex-col w-[100%] h-full   gap-6 md:px-[5rem] xs:px-[1rem] lg:py-[3rem] xs:py-[1rem] md:pb-[35px] md:pt-[2rem] xs:pt-[1rem] xs:pb-px" style={{backgroundColor: color.bgcolor , color:color.textcolor }}>
        <div className="flex lg:flex-row xs:flex-col items-center justify-between lg:gap-14 md:gap-10 xs:gap-6 xs:w-full ">
        {name.map((footer1) => (
            <div
              className="flex flex-col lg:items-start xs:items-center gap-3 lg:w-[42%] xs:w-[95%] lg-[8rem] md:w-[90%] lg:mb-[8rem] "
              style={{ justifyContent: "left" }}
              key={footer1._id}
            >
              <img
                src={footer1.logo[0].url}
                alt="mayapada"
                className=" lg:w-[14rem] xs:w-[6rem] md:w-[13rem] "
              />

              <p className="lg:text-sm xs:text-[8px] lg:text-left xs:text-center md:text-[12px] lg:w-8/12">
                {footer1.notes}
              </p>
            </div>
          ))}

          <div
            className="lg:pb-[8rem] md:pb-[4rem] xs:pb-[4rem] flex flex-row  md:gap-10 xs:gap-6 justify-start md:w-full xs:w-12/12 lg:w-10/12"
            style={{ justifyContent: "right" }}
          >
            {fff.map((data, index) => (
              <div
                className="card lg:max-w-[20%] xs:max-w-[18%] md:max-w-[27%] xs:right-[18%] sm:right-[18%] md:right-[2%] "
                key={data._id}
              >
                <div className="flex flex-col xs:items-start sm:gap-3 xs:gap-2 md:w-[80%] xs:w-[100%] lg:w-[100%]">
                  <div class="bg-[#231F1F] relative lg:right-[10px] md:right-[10px] sm:right-[10px] xs:right-[8px] ">
                    {index >= 1 &&  (
                      <div class="absolute lg:top-[15px] md:top-[17px] xs:top-[11px] -ml-0.5 w-0.5 h-screen  max-h-[18rem] lg:h-[18rem] md:h-[12rem] xs:h-[8rem]" style={{backgroundColor: color.linecolor}}></div>
                    )}
                  </div>
                  <h3 className="lg:text-sm xs:text-[8px] md:text-sm font-semibold " style={{color: color.headercolor}} > 
                    {data.noteRightHeader}
                  </h3>
                  {/* <h3 className="lg:text-sm xs:text-[8px] md:text-sm font-semibold text-white">{data.noteRightHeader}</h3> */}
                  <div className="lg:mt-[5px] flex flex-col gap-1 md:text-[10px] xs:text-[6px] xs:text-left lg:w-[66%] md:w-[90%] xs:w-[58%]">
                    {/* <div class="bg-[#231F1F] relative">
    <div class="absolute  md:left-[-7px] xs:left-[-4px] -ml-0.5 w-0.5 h-screen bg-[#fff] max-h-[18rem] lg:h-[18rem] md:h-[10rem] xs:h-[8rem]"></div>
 </div> */}
                    <span>{data.noteRight}</span>
                  </div>
                </div>
              </div>
            ))}
            {/* <div class="bg-[#231F1F] relative">
    <div class="absolute  md:left-8 xs:left-4 -ml-0.5 w-0.5 h-screen bg-[#fff] max-h-[18rem] lg:h-[18rem] md:h-[14rem] xs:h-[8rem]"></div>
</div>
          <div className="flex flex-col xs:items-start sm:gap-3 xs:gap-2 md:w-2/4 xs:w-[95%] lg:w-3/6">
            <h3 className="lg:text-sm xs:text-[8px] md:text-sm font-semibold text-white">CONTACT</h3>
            <div className="flex flex-col gap-1 md:text-[10px] xs:text-[6px] xs:text-left">
              <span>
              contact @ mayapada.com
              </span>
              <p>www.mayapada.com</p>
            </div>
          </div> */}
          </div>
          {/* <div className="flex flex-col md:items-start xs:items-center gap-3 md:w-1/6 xs:w-[95%]">
            <h3 className="md:text-xl xs:text-base font-semibold">
              Quick Menu
            </h3>
            <ul className="md:text-sm xs:text-[10px] md:text-left xs:text-center">
              <li>Games</li>
              <li>Company</li>
              <li>Careers</li>
              <li>Contact</li>
            </ul>
          </div> */}

          {/* <div className="flex flex-col md:items-start xs:items-center gap-3 md:w-1/6 xs:w-[95%]">
            <h3 className="md:text-xl xs:text-base font-semibold">Socials</h3>
            <ul className="md:text-sm xs:text-[10px] md:text-left xs:text-center">
              <li>Facebook</li>
              <li>Instagram</li>
              <li>Twiter</li>
              <li>Youtube</li>
            </ul>
          </div> */}
        </div>
      </div>
      ))}
      {copyright.map((footer) => (
      <div className="flex flex-col w-[100%]    md:px-[5rem] xs:px-[1rem] md:py-[28px] xs:py-[1rem] h-full" style={{ backgroundColor: footer.bgcolor }} key={footer._id}>
        <p className="text-center xs:text-[10px]  md:text-[10px]" style={{color: footer.textcolor}}>
          {footer.copyright}
        </p>
      </div>
      ))}
    </>
  );
};

export default Footer;
