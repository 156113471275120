import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "../../api/axios";

const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);

  const handleLogin = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await axios.post("/auth/login", { email, password });
      console.log(response);
      localStorage.setItem("token", response.data.token);
      localStorage.setItem("user", JSON.stringify(response.data.user));
      const role = response.data.user.role;
      if (role && role.includes("admin")) {
        console.log("redirect to dashboard admin");
        console.log(role);
        navigate("/dashboardadmin");
      } else {
        console.log("redirect to home");
        navigate("/");
      }
    } catch (err) {
      setError(err.response.data.message);
    }
    setIsLoading(false);
  };

  const handleRememberMe = (e) => {
    setRememberMe(e.target.checked);
    console.log(setRememberMe);
  };

  return (
    <div className="md:flex-row xs:flex-col w-[100vw] lg:py-[10rem] md:py-[8rem] sm:py-[6rem] xs:py-[6rem] min-h-[100vh] lg:justify-around">
      <h1 className="font-semibold text-4xl text-center">Login</h1>
      <div className="grid justify-center mt-5">
        {error && <p>{error}</p>}
        <form onSubmit={handleLogin}>
          <label className="label label-text">
            Email:
            <input
              className="input input-bordered input-error w-80 "
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="example@gmail.com"
            />
          </label>
          <label className="label label-text">
            Password:
            <input
              className="input input-bordered input-error w-80 "
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="type your password"
            />
          </label>
          <div className="flex items-center mt-3">
            <input
              type="checkbox"
              id="rememberMe"
              checked={rememberMe}
              onChange={handleRememberMe}
            />
            <label htmlFor="rememberMe" className="ml-2">
              Remember Me
            </label>
          </div>
          <button
            className=" btn btn-error h-9 mt-5"
            type="submit"
            disabled={isLoading}
          >
            Login
          </button>
        </form>
        <p>
          Not have account?{" "}
          <Link className="link link-error link-hover" to="/register">
            Register here
          </Link>
        </p>
      </div>
    </div>
  );
};

export default Login;
