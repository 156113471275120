import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { Link, useNavigate } from "react-router-dom";
import axios from "../../api/axios";

// icons
import { MdMenu, MdClose } from "react-icons/md";

// assets
import logoMayapada from "../../assets/image/MayapadaLogo.png";

// import { click } from "@testing-library/user-event/dist/click";

function Header() {
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("user"));
  const isAdmin = user && user?.role.includes("admin");
  console.log(isAdmin);
  const firstName = user ? user.firstName.toUpperCase() : null;
  const [showMenu, setShowMenu] = useState(false);

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    navigate("/");
    window.location.reload();
  };

  const [menuOpen, setMenuOpen] = useState(false);

  const handleClick = () => setMenuOpen(!menuOpen);

  const [navSize, setnavSize] = useState("5rem");
  const listenScrollEvent = () => {
    window.scrollY > 10 ? setnavSize("3rem") : setnavSize("5rem");
  };
  const [pages, setPages] = useState([]);
  const [img, setImg] = useState([]);
  const [color, setColor] = useState([]);

  const fetchColor = async () => {
    const color = await axios.get('/headercolor');
    setColor(color.data);
  };

  const fetchData = async () => {
    const headerResponse = await axios.get("/header");
    setPages(headerResponse.data);
    const mayapadaResponse = await axios.get("/mayapada");
    setImg(mayapadaResponse.data[0]);
    console.log(setImg);
    };

  useEffect(() => {
    fetchColor();
    fetchData();
    window.addEventListener("scroll", listenScrollEvent);
    return () => {
      window.removeEventListener("scroll", listenScrollEvent);
    };
  }, []);

  return (
    <>
 {color.map((color) => (
    <header
      className="absolute z-0 w-[100vw] items-center" 
      style={{
        height: navSize,
        transition: "all 2s",
        color: color.textcolor,
        backgroundColor: color.bgcolor
      }}
    >
      {/* destop & table */}
      <div className="hidden md:flex w-full px-[4rem] py-5">
        <motion.div
          className="flex w-full items-center justify-between"
          initial={{ opacity: 0, x: 200 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: 200 }}
        >
          {img.logo && img.logo.length > 0 && (
            <img
              src={img.logo[0].url}
              className="w-[8rem] cursor-pointer"
              alt={img.name}
              onClick={() => navigate("/")}
            />
          )}
          <ul className="flex items-center gap-6 mr-[2rem]">
            {/* mr-2rem hapus saja nek merusak responsive*/}
            {pages.map((page) => (
              <li
                className="text-base duration-100 hover:font-semibold transition-all ease-in-out cursor-pointer"
                key={page._id}
              >
                <Link
                  to={
                    page.name.toLowerCase() === "home"
                      ? "/"
                      : `/${page.name.toLowerCase()}`
                  }
                >
                  {page.name}
                </Link>
              </li>
            ))}
            {/* <li
              className="text-base duration-100 hover:font-semibold  transition-all ease-in-out cursor-pointer"
              // onClick={() => navigate("/aboutpage/about")}
            >
              ABOUT
            </li>
            <li
              className="text-base duration-100 hover:font-semibold   transition-all ease-in-out cursor-pointer"
              // onClick={() => navigate("/productpage/product")}
            >
              PRODUCT
            </li>
             <li className="text-base duration-100 hover:font-semibold  transition-all ease-in-out cursor-pointer">
              Project
            </li>
            <li
              className="text-base duration-100 hover:font-semibold  transition-all ease-in-out cursor-pointer"
              // onClick={() => navigate("/contact/contact")}
            >
              CONTACT
            </li>*/}
            {isAdmin && (
              <li className="text-base duration-100 hover:font-semibold  transition-all ease-in-out cursor-pointer">
                <Link to="/dashboardadmin">DASHBOARD</Link>
              </li>
            )}
            {user ? (
              <li>
                <div className="relative">
                  <div
                    className="text-base duration-100 hover:font-semibold  transition-all ease-in-out cursor-pointer"
                    onClick={() => setShowMenu(!showMenu)}
                  >
                    {firstName}
                  </div>
                  {showMenu && (
                    <div className="absolute right-0 z-10 border rounded shadow-md" style={{backgroundColor: color.dropdowncolor}}>
                      <button
                        className="text-base duration-100 hover:font-semibold  transition-all ease-in-outblock w-full text-left px-4 py-2 border-b hover:bg-gray-100"
                        onClick={handleLogout}
                      >
                        Logout
                      </button>
                    </div>
                  )}
                </div>
              </li>
            ) : (
              <li className="text-base duration-100 hover:font-semibold  transition-all ease-in-out">
                <Link to="/login">LOGIN</Link>
              </li>
            )}
          </ul>
        </motion.div>
      </div>
      <hr className="mx-auto h-px my-2-200  border-2 lg:max-w-[83rem] md:max-w-[39rem] hidden md:block" style={{borderBlockColor: color.linecolor , backgroundColor: color.linecolor}} />

      {/* mobile */}
      <div className="flex md:hidden  w-full h-full">
        <div className="relative w-full ">
          <motion.div
            className="rectangle text-2xl absolute cursor-pointer right-4 top-3 rounded-full"
            viewBox="0 0 100 100"
            onClick={handleClick}
          >
            {menuOpen ? <MdClose /> : <MdMenu />}
          </motion.div>

          {menuOpen && (
            <motion.div
              initial={{ opacity: 0, scale: 0.6 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 1 }}
              className="w-[92%]  bg-opacity-80 px-6 py-5 flex flex-col absolute top-11 right-3 rounded-md"
              style={{backgroundColor: color.bgmobile}}
            >
              <ul className="flex flex-col gap-4 items-end w-full">
                <li className="flex  justify-center w-full py-1">
                  <img
                    src={logoMayapada}
                    className=" w-[4.5rem]  cursor-pointer"
                    alt="logojivaloka"
                    onClick={() => {
                      setMenuOpen(false);
                      navigate("/");
                    }}
                  />
                </li>
                <li
                  className="text-sm duration-100 transition-all ease-in-out cursor-pointer  py-1"
                  onClick={() => {
                    setMenuOpen(false);
                    navigate("/");
                  }}
                >
                  HOME
                </li>
                <li
                  className="text-sm duration-100 transition-all ease-in-out cursor-pointer  py-1"
                  onClick={() => {
                    setMenuOpen(false);
                    // navigate("/aboutpage/about");
                  }}
                >
                  ABOUT
                </li>
                <li
                  className="text-sm duration-100 transition-all ease-in-out cursor-pointer  py-1"
                  onClick={() => {
                    setMenuOpen(false);
                    // navigate("/productpage/product");
                  }}
                >
                  PRODUCT
                </li>
                {/* <li
                  className="text-sm duration-100  transition-all ease-in-out cursor-pointer  py-1"
                  onClick={() => setMenuOpen(false)}
                >
                  Project
                </li> */}
                <li
                  className="text-sm duration-100  transition-all ease-in-out cursor-pointer  py-1"
                  onClick={() => {
                    setMenuOpen(false);
                    // navigate("/contact/contact");
                  }}
                >
                  CONTACT
                </li>
                {/* <li
                  className="text-sm duration-100  transition-all ease-in-out cursor-pointer  py-1"
                  onClick={() => setMenuOpen(false)}
                >
                  Partner
                </li>
                <li
                  className="text-sm duration-100  transition-all ease-in-out cursor-pointer  py-1"
                  onClick={() => setMenuOpen(false)}
                >
                  Contact
                </li> */}
              </ul>
            </motion.div>
          )}
        </div>
      </div>
    </header>
    ))}
    </>
  );
}

export default Header;
