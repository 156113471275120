// import { color } from "framer-motion";
import React, { useState, useEffect } from "react";
import axios from "../../api/axios";
import mail from "../../assets/image/mail.png";
import telp from "../../assets/image/telephone.png";
import parse from 'html-react-parser';

const LandingPage = () => {
  const [content3, setContent3] = useState([]);
  const [content2, setContent2] = useState([]);
  const [content1, setContent1] = useState([]);
  const [contentColor, setContentColor] = useState([]);
  const [bg, setBg] = useState([]);

  const fetchHome = async () => {
    const bgr = await axios.get('/background');
    setBg(bgr.data);
    const content33 = await axios.get("/content3");
    setContent3(content33.data);
    const content22 = await axios.get("/content2");
    setContent2(content22.data);
    const content11 = await axios.get("/content1");
    setContent1(content11.data);
    const contentcolor1 = await axios.get("/contentcolor");
    setContentColor(contentcolor1.data);
  };

  useEffect(() => {
    fetchHome();
  }, []);

  return (
    <>
    {bg.map((bg) => (
    <div key={bg._id} style={{backgroundImage: `url(${bg.image[0].url})` , backgroundColor: bg.color, backgroundRepeat: "no-repeat" }}>
      {contentColor.map((cc) => (
        <div key={cc._id}>
          {content1.map((c1) => (
            <div
              key={c1._id}
              className="flex lg:flex-row md:flex-row xs:flex-col w-[100vw] lg:py-[10rem] md:py-0 min-h-[100vh] lg:justify-around "
            >
              <div className="xs:mt-20 md:mt-60 lg:mt-0">
                <h2 className=" lg:text-3xl md:text-3xl xs:text-xl lg:ml-0 md:ml-[1rem] xs:ml-3" style={{color: cc.labelcolor}}>
                  {parse(c1.welcome)}
                </h2>
                <h1
                  className="font-semibold lg:text-8xl md:text-6xl xs:text-5xl lg:ml-0 md:ml-[1rem] xs:ml-3 mt-1 "
                  style={{ color: cc.txtcolor }}
                >
                  {parse(c1.name)}
                </h1>
                <h1
                  className="mt-8 lg:text-lg md:text-sm xs:text-base lg:ml-0 md:ml-[1rem] xs:ml-3 max-w-[29rem]"
                  style={{ color: cc.txtcolor }}
                >
                  {parse(c1.notes)}
                </h1>
                <br />
                <div className="flex gap-5 lg:mt-10 md:mt-3 xs lg:ml-0 md:ml-[1rem] xs:ml-3">
                  <div className="flex flex-col">
                    <span
                      htmlFor=""
                      className="btn rounded-full text-white h-1 lg:w-40  md:w-35 xs:w-[8rem] btn-disabled"
                      style={{
                        backgroundColor: cc.btncolor,
                        color: cc.txtcolor1,
                      }}
                    >
                      {c1.btntxt1}
                    </span>
                  </div>
                  <div className="flex flex-col">
                    <span
                      htmlFor=""
                      className="btn bg-white border-black rounded-full text-black h-1 lg:w-40  md:w-35 xs:w-[8rem] btn-disabled"
                      style={{ background: cc.btncolor2, color: cc.txtcolor }}
                    >
                      {c1.btntxt2}
                    </span>
                  </div>
                </div>
              </div>
              <div className="flex flex-col items-center z-0 p-[1rem] lg:mt-0 md:mt-60 xs:mt-10 ">
                <img
                  src={c1.image && c1.image[0].url}
                  alt={c1.image && c1.image[0].fileName}
                  className="lg:w-[44rem] md:w-[24rem] xs:w-[24rem]"
                />
              </div>
            </div>
          ))}

          <div className="mt-[13rem] ">
            {content1.map((c1) => (
              <h1
                key={c1._id}
                className="card text-center text-3xl font-semibold "
                style={{ color: cc.txtcolor }}
              >
                {c1.product}
              </h1>
            ))}

            <div
              className="flex lg:flex-row md:flex-col xs:flex-col justify-center lg:gap-10 md:gap-20 mt-20 "
              style={{ color: cc.txtcolor }}
            >
              {content2.map((data) => (
                <div
                  key={data.name}
                  className="card md:max-w-[25rem] xs:max-w-[30rem] xs:mt-10 md:mt-0 md:left-[25%] lg:left-0"
                >
                  <div className="text-center md:px-4 xs:px-20">
                    <h1 className="font-extrabold lg:text-3xl md:text-2xl xs:text-2xl ">
                      {parse(data.name)}
                    </h1>
                    <h1 className="mt-3 lg:text-lg md:text-base xs:text-base">
                      {parse(data.notes)}
                    </h1>
                  </div>
                </div>
              ))}
            </div>

            <hr className="mx-auto h-px mt-[10rem] bg-gray-200 border-2 dark:bg-gray-700 lg:max-w-[83rem] md:max-w-[39rem] hidden md:block" />
          </div>
          {content3.map((c1) => (
            <div
              key={c1._id}
              className="lg:flex lg:flex-row md:flex md:flex-row xs:flex xs:flex-row xs:gap-6 mt-20 mb-20 lg:px-0 md:px-28 xs:px-6"
            >
              <div className="lg:pl-40 ">
                <h1
                  className="font-extrabold md:text-5xl xs:text-2xl "
                  style={{ color: cc.txtcolor }}
                >
                  {parse(c1.name)}
                </h1>
                <div
                  className="btn btn-disabled text-white rounded-xl lg:mt-5 text-xs lg:px-7 md:mt-12 xs:mt-10"
                  style={{ backgroundColor: cc.btncolor }}
                >
                  {c1.txtbtn}
                </div>
              </div>

              <div className="lg:flex md:flex-none gap-5 lg:pr-40">
                <div
                  className="card btn-disabled lg:w-64 md:w-64 xs:w-56 text-center lg:gap-3 py-8 "
                  style={{ backgroundColor: cc.cardcolor }}
                >
                  <h1 className="text-sm " style={{ color: cc.txtcolor1 }}>
                    {c1.txtcard1}
                  </h1>
                  <div className="flex justify-center items-center">
                    <img src={mail} alt="" className="" />
                  </div>
                  <h1 className="text-sm" style={{ color: cc.txtcolor1 }}>
                    {c1.email}
                  </h1>
                </div>
                <div
                  className="card btn-disabled lg:w-64 md:w-64 xs:w-56 text-center lg:gap-3 py-8 lg:mt-0 md:mt-3 xs:mt-2"
                  style={{ backgroundColor: cc.cardcolor }}
                >
                  <h1 className="text-sm" style={{ color: cc.txtcolor1 }}>
                    {c1.txtcard2}{" "}
                  </h1>
                  <div className="flex justify-center items-center">
                    <img src={telp} alt="" className="" />
                  </div>
                  <h1 className="text-sm" style={{ color: cc.txtcolor1 }}>
                    {c1.telp}
                  </h1>
                </div>
              </div>
            </div>
          ))}
        </div>
      ))}
    </div>
     ))}
     </>
  );
};

export default LandingPage;
