import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "../../api/axios";

const Register = () => {
  const navigate = useNavigate();
  const [firstName, setfirstName] = useState("");
  const [lastName, setlastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await axios.post("/auth/register", {
        firstName,
        lastName,
        email,
        password,
      });
      console.log(response.data);
      navigate("/");
    } catch (err) {
      setError(err.response.data.message);
    }
    setIsLoading(false);
  };

  return (
    <div className="  md:flex-row xs:flex-col w-[100vw] lg:py-[10rem] md:py-0 min-h-[100vh] lg:justify-around ">
      <h1 className="font-semibold text-4xl text-center">Register</h1>
      <div className="grid justify-center mt-5">
        <p>{error}</p>
        <form onSubmit={handleSubmit}>
          <label className="label label-text">
            First Name:
            <input
              className="input input-bordered input-error w-80 "
              type="text"
              value={firstName}
              onChange={(e) => setfirstName(e.target.value)}
              placeholder="your first name"
            />
          </label>
          <label className="label label-text">
            Last Name:
            <input
              className="input input-bordered input-error w-80 "
              type="text"
              value={lastName}
              onChange={(e) => setlastName(e.target.value)}
              placeholder="your last name"
            />
          </label>
          <label className="label label-text">
            Email:
            <input
              className="input input-bordered input-error w-80 "
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="example@gmail.com"
            />
          </label>
          <label className="label label-text">
            Password:
            <input
              className="input input-bordered input-error w-80 "
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="type your password"
            />
          </label>
          <button
            className=" btn btn-error h-9 mt-5"
            type="submit"
            disabled={isLoading}
          >
            Register
          </button>
          <p>
            Already have an account?{" "}
            <Link className="link link-error link-hover" to="/login">
              Login here
            </Link>
          </p>
        </form>
      </div>
    </div>
  );
};

export default Register;
