import React, { useState, useEffect } from "react";
import axios from "../../api/axios";
import Modal from "react-modal";
import { FaEdit, FaTrash } from "react-icons/fa";
import Editor from 'react-simple-wysiwyg';
import parse from 'html-react-parser';

export default function CrudContent1() {
  const [data, setData] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [formValues, setFormValues] = useState({
    name: null,
    notes: "",
    logo: [],
    // bgcolor: "",
    // textcolor: "",
    // headercolor: "",
  });

  const closeModal = () => {
    setModalIsOpen(false);
    setFormValues({
      _id: "",
      name: null,
      notes: "",
      logo: [],
    //   bgcolor: "",
    //   textcolor: "",
    //   headercolor: "",
    });
  };

  const fetchData = async () => {
    const response = await axios.get("/footer1");
    setData(response.data);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === "logo") {
      setFormValues({ ...formValues, [name]: event.target.files[0] });
    } else {
      setFormValues({ ...formValues, [name]: value });
    }
  };
  
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const token = localStorage.getItem("token");
      console.log(token);
      if (!token) {
        console.log("Token not found");
        return;
      }
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const formData = new FormData();
      formData.append("file", formValues.logo);
      const response = await axios.post("/storage/upload", formData, config);
      const image = { fileName: response.data.fileName, url: response.data.url };
      const data = { ...formValues, logo: [image] };
      await axios.post("/footer1", data, config);
      setModalIsOpen(false);
      fetchData();
    } catch (error) {
      console.log(error);
    }
  };

  const handleDelete = async (_id) => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        console.log("Token not found");
        return;
      }
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      await axios.delete(`/footer1/${_id}`, config);
      fetchData();
    } catch (error) {
      console.log(error);
    }
  };

  const handleEdit = async (_id) => {
    const newData = data.find((item) => item._id === _id);
    setFormValues(newData);
    setModalIsOpen(true);
  };

  const handleUpdate = async (event) => {
    event.preventDefault();
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        console.log("Token not found");
        return;
      }
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const formData = new FormData();
      formData.append("file", formValues.logo);
      const response = await axios.post("/storage/upload", formData, config);
      const image = { fileName: response.data.fileName, url: response.data.url };
      const data = { 
        notes: formValues.notes ,
        logo: [image],
        }
      await axios.patch(`/footer1/${formValues._id}`, data, config);
      setModalIsOpen(false);
      fetchData();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="container mx-auto">
      <div className="mt-4">
        <button
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          onClick={() => setModalIsOpen(true)}
        >
          Add Data
        </button>
      </div>
      <table className="table-auto mt-4">
        <thead>
          <tr>
            <th className="px-4 py-2">#</th>
            <th className="px-4 py-2">Notes</th>
            <th className="px-4 py-2">Logo</th>
            {/* <th className="px-4 py-2">Background Color</th>
            <th className="px-4 py-2">Text Color</th>
            <th className="px-4 py-2">Header Text Color</th> */}
            <th className="px-4 py-2">Action</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={item._id}>
              <td className="border px-4 py-2">{index + 1}</td>
              <td className="border px-4 py-2">{parse(item.notes)}</td>
              <td className="border px-4 py-2">
                <img
                  src={item.logo && item.logo[0].url}
                  alt={item.logo && item.logo[0].fileName}
                  className="object-contain h-48 w-full"
                />
              </td>
              {/* <td className="border px-4 py-2">{item.bgcolor}</td>
              <td className="border px-4 py-2">{item.textcolor}</td>
              <td className="border px-4 py-2">{item.headercolor}</td> */}
              <td className="border px-4 py-2">
                <FaEdit
                  className="cursor-pointer text-blue-500 hover:text-blue-700 mr-2"
                  onClick={() => handleEdit(item._id)}
                />
                <FaTrash
                  className="cursor-pointer text-red-500 hover:text-red-700"
                  onClick={() => handleDelete(item._id)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Modal isOpen={modalIsOpen}>
        <div className="z-10 bg-white rounded-md p-4">
          <form onSubmit={formValues._id ? handleUpdate : handleSubmit}>
          <div className="mb-4">
              <label
                className="block text-gray-700 font-bold mb-2"
                htmlFor="logo"
              >
                Logo
              </label>
              <input
                className="appearance-none border rounded py-2 px-3 text-black bg-white leading-tight focus:outline focus:shadow-outline"
                id="logo"
                type="file"
                placeholder="Logo"
                name="logo"
                // value={formValues.logo}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="mb-4">
              <label
                className="block text-gray-700 font-bold mb-2"
                htmlFor="notes"
              >
                Notes
              </label>
              <Editor
                className="appearance-none border rounded w-full py-2 px-3 text-black bg-white leading-tight focus:outline focus:shadow-outline"
                _id="notes"
                type="text"
                placeholder="Notes"
                name="notes"
                value={formValues.notes}
                onChange={handleInputChange}
                required
              />
            </div>
            {/* <div className="mb-4">
              <label
                className="block text-gray-700 font-bold mb-2"
                htmlFor="bgcolor"
              >
                Background Color
              </label>
              <Editor
                className="appearance-none border rounded w-full py-2 px-3 text-black bg-white leading-tight focus:outline focus:shadow-outline"
                _id="bgcolor"
                type="text"
                placeholder="Background Color"
                name="bgcolor"
                value={formValues.bgcolor}
                onChange={handleInputChange}
                required
              ></Editor>
            </div>
            <div className="mb-4">
              <label
                className="block text-gray-700 font-bold mb-2"
                htmlFor="textcolor"
              >
                Text Color
              </label>
              <Editor
                className="appearance-none border rounded w-full py-2 px-3 text-black bg-white leading-tight focus:outline focus:shadow-outline"
                _id="textcolor"
                type="text"
                placeholder="Text Color"
                name="textcolor"
                value={formValues.textcolor}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="mb-4">
              <label
                className="block text-gray-700 font-bold mb-2"
                htmlFor="headercolor"
              >
                Header Text Color
              </label>
              <Editor
                className="appearance-none border rounded w-full py-2 px-3 text-black bg-white leading-tight focus:outline focus:shadow-outline"
                _id="headercolor"
                type="text"
                placeholder="Header Text Color"
                name="headercolor"
                value={formValues.headercolor}
                onChange={handleInputChange}
                required
              />
            </div> */}
            <div className="flex justify-end">
              <button
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-2"
                type="submit"
              >
                {formValues._id ? "Update" : "Save"}
              </button>
              <button
                className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
                onClick={closeModal}
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </Modal>
    </div>
  );
}
