import React, { useState, useEffect } from "react";
import axios from "../../api/axios";
import Modal from "react-modal";
import { FaEdit, FaTrash } from "react-icons/fa";
import Editor from 'react-simple-wysiwyg';
import parse from 'html-react-parser';

export default function CrudContent1() {
  const [data, setData] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [formValues, setFormValues] = useState({
    name: "",
    notes: "",

  });

  const closeModal = () => {
    setModalIsOpen(false);
    setFormValues({
      _id: "",
      name: "",
      notes: "",

    });
  };

  const fetchData = async () => {
    const response = await axios.get("/content2");
    setData(response.data);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
      setFormValues({ ...formValues, [name]: value });
  };
  
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const token = localStorage.getItem("token");
      console.log(token);
      if (!token) {
        console.log("Token not found");
        return;
      }
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
    //   const formData = new FormData();
    //   formData.append("file", formValues.image);
    //   const response = await axios.post("/storage/upload", formData, config);
    //   const image = { fileName: response.data.fileName, url: response.data.url };
      const data = { ...formValues };
      await axios.post("/content2", data, config);
      setModalIsOpen(false);
      fetchData();
    } catch (error) {
      console.log(error);
    }
  };

  const handleDelete = async (_id) => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        console.log("Token not found");
        return;
      }
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      await axios.delete(`/content2/${_id}`, config);
      fetchData();
    } catch (error) {
      console.log(error);
    }
  };

  const handleEdit = async (_id) => {
    const newData = data.find((item) => item._id === _id);
    setFormValues(newData);
    setModalIsOpen(true);
  };

  const handleUpdate = async (event) => {
    event.preventDefault();
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        console.log("Token not found");
        return;
      }
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const data = { name: formValues.name ,
        notes: formValues.notes }
      await axios.patch(`/content2/${formValues._id}`, data, config);
      setModalIsOpen(false);
      fetchData();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="container mx-auto">
      <div className="mt-4">
        <button
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          onClick={() => setModalIsOpen(true)}
        >
          Add Data
        </button>
      </div>
      <table className="table-auto mt-4">
        <thead>
          <tr>
            <th className="px-4 py-2">#</th>
            <th className="px-4 py-2">Name</th>
            <th className="px-4 py-2">Notes</th>
            {/* <th className="px-4 py-2">Image</th>
            <th className="px-4 py-2">Welcome</th>
            <th className="px-4 py-2">Business</th>
            <th className="px-4 py-2">Email</th>
            <th className="px-4 py-2">Telp</th>
            <th className="px-4 py-2">Product</th>
            <th className="px-4 py-2">Button Text 1</th>
            <th className="px-4 py-2">Button Text 2</th> */}
            <th className="px-4 py-2">Action</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={item._id}>
              <td className="border px-4 py-2">{index + 1}</td>
              <td className="border px-4 py-2">{parse(item.name)}</td>
              <td className="border px-4 py-2">{parse(item.notes)}</td>
              {/* <td className="border px-4 py-2">
                <img
                  src={item.image && item.image[0].url}
                  alt={item.image && item.image[0].fileName}
                  className="object-contain h-48 w-full"
                />
              </td>
              <td className="border px-4 py-2">{item.welcome}</td>
              <td className="border px-4 py-2">{item.business}</td>
              <td className="border px-4 py-2">{item.email}</td>
              <td className="border px-4 py-2">{item.telp}</td>
              <td className="border px-4 py-2">{item.product}</td>
              <td className="border px-4 py-2">{item.btntxt1}</td>
              <td className="border px-4 py-2">{item.btntxt2}</td> */}
              <td className="border px-4 py-2">
                <FaEdit
                  className="cursor-pointer text-blue-500 hover:text-blue-700 mr-2"
                  onClick={() => handleEdit(item._id)}
                />
                <FaTrash
                  className="cursor-pointer text-red-500 hover:text-red-700"
                  onClick={() => handleDelete(item._id)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Modal isOpen={modalIsOpen}>
        <div className="z-10 bg-white rounded-md p-4">
          <form onSubmit={formValues._id ? handleUpdate : handleSubmit}>
            <div className="mb-4">
              <label
                className="block text-gray-700 font-bold mb-2"
                htmlFor="name"
              >
                Name
              </label>
              <Editor
                className="appearance-none border rounded w-full py-2 px-3 text-black bg-white leading-tight focus:outline focus:shadow-outline"
                _id="name"
                type="text"
                placeholder="Name"
                name="name"
                value={formValues.name}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="mb-4">
              <label
                className="block text-gray-700 font-bold mb-2"
                htmlFor="notes"
              >
                Notes
              </label>
              <Editor
                className="appearance-none border rounded w-full py-2 px-3 text-black bg-white leading-tight focus:outline focus:shadow-outline"
                _id="notes"
                placeholder="Notes"
                name="notes"
                value={formValues.notes}
                onChange={handleInputChange}
                required
              ></Editor>
            </div>
            {/* <div className="mb-4">
              <label
                className="block text-gray-700 font-bold mb-2"
                htmlFor="image"
              >
                Image
              </label>
              <Editor
                className="appearance-none border rounded py-2 px-3 text-black bg-white leading-tight focus:outline focus:shadow-outline"
                id="image"
                type="file"
                placeholder="File"
                name="image"
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="mb-4">
              <label
                className="block text-gray-700 font-bold mb-2"
                htmlFor="welcome"
              >
                Welcome
              </label>
              <Editor
                className="appearance-none border rounded w-full py-2 px-3 text-black bg-white leading-tight focus:outline focus:shadow-outline"
                _id="welcome"
                type="text"
                placeholder="Welcome Text"
                name="welcome"
                value={formValues.welcome}
                onChange={handleInputChange}
                required
              ></Editor>
            </div>
            <div className="mb-4">
              <label
                className="block text-gray-700 font-bold mb-2"
                htmlFor="business"
              >
                Business
              </label>
              <Editor
                className="appearance-none border rounded w-full py-2 px-3 text-black bg-white leading-tight focus:outline focus:shadow-outline"
                _id="business"
                type="text"
                placeholder="Business Name"
                name="business"
                value={formValues.business}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="mb-4">
              <label
                className="block text-gray-700 font-bold mb-2"
                htmlFor="email"
              >
                Email
              </label>
              <Editor
                className="appearance-none border rounded w-full py-2 px-3 text-black bg-white leading-tight focus:outline focus:shadow-outline"
                _id="email"
                type="email"
                placeholder="Email Address"
                name="email"
                value={formValues.email}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="mb-4">
              <label
                className="block text-gray-700 font-bold mb-2"
                htmlFor="telp"
              >
                Phone Number
              </label>
              <Editor
                className="appearance-none border rounded w-full py-2 px-3 text-black bg-white leading-tight focus:outline focus:shadow-outline"
                _id="telp"
                type="text"
                placeholder="Phone Number"
                name="telp"
                value={formValues.telp}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="mb-4">
              <label
                className="block text-gray-700 font-bold mb-2"
                htmlFor="product"
              >
                Product
              </label>
              <Editor
                className="appearance-none border rounded w-full py-2 px-3 text-black bg-white leading-tight focus:outline focus:shadow-outline"
                _id="product"
                type="text"
                placeholder="Product"
                name="product"
                value={formValues.product}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="mb-4">
              <label
                className="block text-gray-700 font-bold mb-2"
                htmlFor="btntxt1"
              >
                Button Text 1
              </label>
              <Editor
                className="appearance-none border rounded w-full py-2 px-3 text-black bg-white leading-tight focus:outline focus:shadow-outline"
                _id="btntxt1"
                type="text"
                placeholder="Button Text 1"
                name="btntxt1"
                value={formValues.btntxt1}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="mb-4">
              <label
                className="block text-gray-700 font-bold mb-2"
                htmlFor="btntxt2"
              >
                Button Text 2
              </label>
              <Editor
                className="appearance-none border rounded w-full py-2 px-3 text-black bg-white leading-tight focus:outline focus:shadow-outline"
                _id="btntxt2"
                type="text"
                placeholder="Button Text 2"
                name="btntxt2"
                value={formValues.btntxt2}
                onChange={handleInputChange}
                required
              />
            </div> */}
            <div className="flex justify-end">
              <button
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-2"
                type="submit"
              >
                {formValues._id ? "Update" : "Save"}
              </button>
              <button
                className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
                onClick={closeModal}
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </Modal>
    </div>
  );
}
